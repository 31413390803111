<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
          id="ChooseNotice"
          v-if="(ChooseID.length > 0)"
          color="info"
          :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseID.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseID = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex justify-content-between align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Offer/RaffleActivity') }}</h5>
            </CCol>
            <CCol col="6">
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" @click="CreateActivityModal()">
                  {{ $t('Global.Add') + '活動' }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="RaffleActivityList"
            :items="List"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-DiscountTicket-' + item._id" :value="item._id" v-model="ChooseID">
                </label>
              </td>
            </template>
            <template #Name="{item}">
              <td>
                {{ item.Name }}<br>
                <CBadge :color=" item.Event.TriggerIssue === 'NA' ? 'info' : 'success'">{{ $t('Offer.EventTriggerRaffleDrawOptions.' + item.Event.TriggerIssue) }}</CBadge>
              </td>
            </template>
            <template #AchieveTarget="{item}">
              <td>
                {{ (item.Variable.UsedTickets || []).length }} / {{ item.Event.TargetQuantity }} ({{ (item.Variable.UsedTickets || []).length / parseInt(item.Event.TargetQuantity) * 100 }}%)
              </td>
            </template>
            <template #Result="{item}">
              <td>
                <b>開獎狀態</b>: <CBadge :color="(item.Variable.ResultTicketID ? 'success' : 'warning')">{{ item.Variable.ResultTicketID ? $t('Offer.StatusOptions.RF') : $t('Offer.StatusOptions.RU') }}</CBadge><br>
                <b>開獎號碼</b>: {{ item.Variable.ResultTicketID || '--' }}
              </td>
            </template>
            <template #TimeRange="{item}">
              <td>
                <b>{{ $t('Offer.StartTime') }}</b>: {{ $dayjs(item.StartTime).format('YYYY-MM-DD HH:mm:ss') }}<br>
                <b>{{ $t('Offer.EndTime') }}</b>: {{ $dayjs(item.EndTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 'Y' ? 'success' : 'danger')">
                  {{ $t('Offer.StatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item, index}">
              <td>
                <CButton v-if="item.Status === 'Y' && item.Event.TriggerIssue === 'NA' && !item.Variable.ResultTicketID" color="success" size="sm" class="mr-1" v-c-tooltip="'開獎'" @click="GetResult(item)">
                  <CIcon name="cil-paper-plane" class="c-icon-custom-size" />
                </CButton>
                <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Global.Edit')" @click="EditActivityModal(index)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size" />
                </CButton>
                <CButton color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Delete')" @click="DeleteModal(item._id)">
                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
            :activePage.sync="Pagination.Page"
            :pages.sync="Pagination.TotalPages"
            @update:activePage="GetList()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="CreateModal" class="ActionModel" :show.sync="CreateModal" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ Action === 'Edit' ? '編輯' : '新增' }}抽獎活動</h5>
          <CButtonClose @click="CreateModal = false"/>
        </header>
      </template>
      <CTabs v-if="CurrentActivity" fade addTabsWrapperClasses="tab-info">
        <CTab title="基礎設定" active>
          <CInput v-model="CurrentActivity.Name" :label="'抽獎名稱'" type="text" />
          <CTextarea v-model="CurrentActivity.Description" :label="'抽獎活動說明'" rows="3" />
          <CSelect :label="'開獎時機'" :options="EventTriggerIssueOptions" v-model="CurrentActivity.Event.TriggerIssue" :value.sync="CurrentActivity.Event.TriggerIssue" :placeholder="$t('Global.PleaseSelect')" />
          <CInput v-model="CurrentActivity.Event.TargetQuantity" type="number">
            <template #label>
              <label class="mr-1">開獎目標</label>
              <i v-c-tooltip="{content: '若開獎時機設定「當達到抽獎目標時」且此抽獎活動投入的抽獎券到達此目標，系統將於指定時間抽出得獎者，並且結束當前抽獎活動。',placement: 'right'}" class="fas fa-question-circle text-info" />
            </template>
          </CInput>
          <div class="form-group">
            <label class="d-block">{{ $t('Offer.StartTime') }}</label>
            <v-date-picker v-model="CurrentActivity.StartTime" mode="dateTime" is24hr>
              <template v-slot="{ inputValue, inputEvents }">
                <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                  <template #append-content>
                    <CIcon name="cil-calendar"/>
                  </template>
                </CInput>
              </template>
            </v-date-picker>
          </div>
          <div class="form-group">
            <label class="d-block">{{ $t('Offer.EndTime') }}</label>
            <v-date-picker v-model="CurrentActivity.EndTime" mode="dateTime" is24hr>
              <template v-slot="{ inputValue, inputEvents }">
                <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                  <template #append-content>
                    <CIcon name="cil-calendar"/>
                  </template>
                </CInput>
              </template>
            </v-date-picker>
          </div>
          <div class="form-group">
            <label class="d-block">{{ $t('Offer.Status') }}</label>
            <CSwitch color="success" :checked.sync="StatusSwitch"/>
          </div>
        </CTab>
        <CTab title="商品設定">
          <div class="font-weight-bold d-flex justify-content-between mb-2">
            <label>{{ $t('Offer.SelectItem') }}</label>
            <CButton color="success" size="sm" variant="outline" v-c-tooltip="$t('Global.Add')" @click="OpenProductListModel('TargetItem')">
              <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}
            </CButton>
          </div>
          <CDataTable
            :loading="Loading"
            id="SelectItemList"
            :items="CurrentActivity.Offer.TargetItem"
            :fields="TargetItemField"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #Cover="{item}">
              <td>
                <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
              </td>
            </template>
            <template #TargetTicket="{item}">
              <td>
                <CInput
                  v-model="item.TargetTicket"
                  type="number"
                  size="sm"
                />
              </td>
            </template>
            <template #Action="{index}">
              <td>
                <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveTargetItems(index)">
                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CreateModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="Save()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="false">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false;ChooseID = []" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false;ChooseID = []" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <ProductList :Single="Single" :Toggle.sync="ProductListModel" :ChooseProductType.sync="ChooseProductType" @ChooseProduct="ChooseProduct" />
  </main>
</template>

<route>
{
"meta": {
"label": "建立抽獎活動"
}
}
</route>

<script>
export default {
  name: 'RaffleActivity',
  layout: 'manage',
  components: {
    ProductList: () => import('@/components/product/ProductList')
  },
  data () {
    return {
      List: [],
      ChooseID: [],
      CheckAllValue: false,
      AlertModel: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Action: 'Edit',
      CurrentActivity: null,
      FilterModel: false,
      ShowFilter: 'All',
      Order: 'nto',
      PageList: [],
      Loading: false,
      Submit: false,
      CreateModal: false,
      StatusSwitch: true,
      ProductListModel: false,
      ProductListModelComponent: null,
      ChooseProductType: null,
      Single: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: '活動名稱' },
        { key: 'TimeRange', label: '活動期間' },
        { key: 'AchieveTarget', label: '參與進度' },
        { key: 'Result', label: '開獎狀態' },
        { key: 'Status', label: this.$t('Offer.Status') },
        { key: 'Action', label: '' }
      ]
    },
    TargetItemField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Action', label: '' }
      ]
    },
    StatusOptions() {
      return Object.keys(this.$t('Offer.StatusOptions')).filter(type => type !== 'E').map(type => {
        return {
          value: type,
          label: this.$t('Offer.StatusOptions')[type]
        }
      })
    },
    EventTriggerIssueOptions() {
      return Object.keys(this.$t('Offer.EventTriggerRaffleDrawOptions')).filter(type => type === 'NA').map(type => {
        return {
          value: type,
          label: this.$t('Offer.EventTriggerRaffleDrawOptions')[type]
        }
      })
    },
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const Data = {
        Taxonomy: 'RaffleActivity',
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage,
        Order: 'nto',
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/manage/list',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.List = data.Data.map(item => {
          if (item.Status === 'Y' && (this.$dayjs().unix() > this.$dayjs(item.EndTime).unix() || this.$dayjs().unix() < this.$dayjs(item.StartTime).unix())) {
            item.Status = 'E'
          }
          return item
        })
        this.Pagination = data.Pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseID = this.List.map(data => {
          return data._id
        })
      } else {
        this.CheckAllValue = false
        this.ChooseID = []
      }
    },
    DeleteModal (ID) {
      this.ChooseID = [ID]
      this.AlertModel = true
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/delete',
          Data: {
            Taxonomy: 'RaffleActivity',
            ID: this.ChooseID
          }
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.ChooseID = []
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Offer/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Offer/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CreateActivityModal () {
      this.CurrentActivity = {
        Taxonomy: 'RaffleActivity',
        Name: '抽獎活動: ' + this.$dayjs().format('YYYY-MM-DD'),
        Description: '',
        Slug: Math.random().toString(36).substring(3, 8).toUpperCase(),
        Offer: {
          Target: 'NA',
          DiscountType: 'NA',
          FreeLogisticsFee: false,
          FreeServiceFee: false,
          TargetItem: []
        },
        Event: {
          TriggerIssue: 'NA',
          TargetQuantity: 100,
          TriggerPage: [
            {
              Name: this.$t('Offer.AllUsagePage'),
              Slug: "ALL"
            }
          ],
        },
        Condition: [],
        TargetGroup: {
          Type: 'AllMember',
          List: []
        },
        UsagePage: [
          {
            Name: this.$t('Offer.AllUsagePage'),
            Slug: "ALL"
          }
        ],
        Variable: {},
        Status: 'Y',
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'month').format('YYYY-MM-DD 23:59:59')).unix() * 1000
      }
      this.CreateModal = true
      this.Action = 'Add'
    },
    EditActivityModal (index) {
      this.CurrentActivity = this.List[index]
      delete this.CurrentActivity.__v
      this.StatusSwitch = this.CurrentActivity.Status === 'Y'
      this.CreateModal = true
      this.Action = 'Edit'
    },
    OpenProductListModel(type, index = null) {
      this.ChooseProductType = type
      this.ChooseConditionIndex = index
      this.ProductListModel = true
      this.Single = type === 'Gift';
      // this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    ChooseProduct(items) {
      items.filter(item => item.Status === 1).forEach(item => {
        const Data = {
          Cover: item.Cover,
          SeriesNum: item.SeriesNum,
          ID: item._id,
          Name: item.Name
        }
        switch (this.ChooseProductType) {
          case 'Default':
          case 'TargetItem':
            if (this.CurrentActivity.Offer.TargetItem.filter(data => data.SeriesNum === Data.SeriesNum).length > 0) {
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Info'),
                text: this.$t('Message.Offer/ChooseProductFail-2'),
                type: 'warn'
              })
              return false
            }
            this.CurrentActivity.Offer.TargetItem.push(Data)
            break
          case 'Gift':
            if (typeof this.ChooseConditionIndex === 'number') {
              this.$set(this.Data.Condition[this.ChooseConditionIndex], 'ItemData', {
                ...Data,
                Variable: item.Variable,
                EnableOption: item.EnableOption,
                EnableStock: item.EnableStock,
                Stock: item.Stock,
                Status: item.Status
              })
            }
            break
          case 'SelectGift':
            if (typeof this.ChooseConditionIndex === 'number') {
              if (!this.Data.Condition[this.ChooseConditionIndex].ItemDataArray) {
                this.$set(this.Data.Condition[this.ChooseConditionIndex], 'ItemDataArray', [])
              }
              this.Data.Condition[this.ChooseConditionIndex].ItemDataArray.push({
                ...Data,
                Variable: item.Variable,
                EnableOption: item.EnableOption,
                EnableStock: item.EnableStock,
                Stock: item.Stock,
                Status: item.Status
              })
            }
            break
        }
      })
      this.CloseProductListModel()
    },
    CloseProductListModel() {
      this.ProductListModel = false
      this.ProductListModelComponent = null
    },
    RemoveTargetItems (index) {
      this.CurrentActivity.Offer.TargetItem.splice(index, 1)
    },
    Save() {
      if (this.CurrentActivity.Name === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/NameEmpty'),
          type: 'warn'
        })
        return
      }
      if (this.CurrentActivity.Slug === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/SlugEmpty'),
          type: 'warn'
        })
        return
      }
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Offer/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        this.CreateModal = false
        this.GetList()
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Offer/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.CurrentActivity.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/add',
          Data: {
            ...this.CurrentActivity,
            StartTime: this.$dayjs(this.CurrentActivity.StartTime).unix(),
            EndTime: this.$dayjs(this.CurrentActivity.EndTime).unix(),
            Status: this.StatusSwitch === true ? 'Y' : 'N'
          }
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Offer/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.CurrentActivity.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      const UpdateData = {
        ...this.CurrentActivity,
        StartTime: this.$dayjs(this.CurrentActivity.StartTime).unix(),
        EndTime: this.$dayjs(this.CurrentActivity.EndTime).unix(),
        Status: this.StatusSwitch === true ? 'Y' : 'N'
      }
      delete UpdateData._id
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/edit',
          Data: {
            Taxonomy: 'RaffleActivity',
            ID: this.CurrentActivity._id,
            UpdateData
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Offer/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetResult(offer) {
      return this.$swal({
        icon: 'info',
        title: '是否開獎？',
        text: '一但開獎系統將直接派發獎項至會員中，請確認是否開獎？',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.Submit = true
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            url: '/offer/getRaffleActivityResult',
            method: 'post',
            data: {
              Slug: offer.Slug
            }
          }).then(() => {
            this.$Progress.finish()
            this.GetList()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Offer/GetResultSuccess'),
              type: 'success'
            })
          }).catch((err) => {
            this.$Progress.fail()
            this.Submit = false
            this.AlertModel = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Offer/GetResultFail') + err.msg,
              type: 'error'
            })
          })
        }
      })
    }
  }
}
</script>

<style>
#RaffleActivityList thead th:first-child {
  text-align: center;
}
#RaffleActivityList tbody td:first-child {
  vertical-align: middle;
}
</style>
